@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  *::selection {
    @apply bg-primary text-white;
  }
  body {
    @apply font-base text-title bg-white;
  }
  button {
    @apply transition-all duration-300;
  }
  .custom-search-select .css-26l3qy-menu {
    @apply z-20;
  }
  .custom-search-select .css-1s2u09g-control,
  .custom-search-select .css-1pahdxg-control {
    min-height: 32px;
    height: 32px;
    border-radius: 6px;
    font-size: 13px;
    width: 100%;
    box-shadow: none;
    padding-left: 20px;
    @apply border-borderColor-200;
  }
  .custom-search-select .css-1s2u09g-control:hover,
  .custom-search-select .css-1pahdxg-control:hover {
    @apply border-borderColor-200;
    box-shadow: none;
  }
  .custom-search-select .css-14el2xx-placeholder {
    @apply text-greyColor-900;
  }
  .custom-search-select .css-1hb7zxy-IndicatorsContainer {
    display: none;
  }
  .custom-search-select .css-4ljt47-MenuList {
    font-size: 13px;
  }
  .custom-search-select.for-add-user .css-1s2u09g-control,
  .custom-search-select.for-add-user .css-1pahdxg-control {
    min-height: 50px;
    height: 50px;
    width: 334px;
    padding-left: 18px;
  }
  @media screen and (min-width: 640px) {
    .custom-search-select .css-1s2u09g-control,
    .custom-search-select .css-1pahdxg-control {
      width: 150px;
    }
  }
  @media screen and (min-width: 992px) {
    .custom-search-select .css-1s2u09g-control,
    .custom-search-select .css-1pahdxg-control {
      width: 184px;
    }
  }
  .datetime-reset-button {
    visibility: hidden;
  }
  .custom-search-create {
    padding-left: 0;
    padding-right: 0;
  }
  .custom-search-create .css-1s2u09g-control,
  .custom-search-create .css-1pahdxg-control {
    width: 100%;
    padding: 0;
    border: 0;
  }
  .custom-search-create .css-319lph-ValueContainer {
    padding: 0 16px;
    font-size: 16px;
  }
  .custom-search-create .css-qc6sy-singleValue {
    font-size: 16px;
  }
  .custom-search-create .css-1s2u09g-control,
  .custom-search-create .css-1pahdxg-control {
    height: auto;
  }
  .custom-search-create .css-1rhbuit-multiValue {
    @apply bg-primary-navyBlue bg-opacity-5;
  }
  .custom-scroll-wrapper {
    overflow-y: scroll !important;
    scrollbar-color: rgba(0, 0, 0, 0.2) white;
    scrollbar-width: thin;
  }
  .custom-scroll-wrapper::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: transparent;
  }
  .custom-scroll-wrapper::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
  .custom-datepicker-wrapper .react-datepicker-wrapper .react-datepicker__input-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @apply z-10 ring-transparent ring-0;
  }
  .custom-datepicker-wrapper .react-datepicker-wrapper input {
    padding: 0 16px;
    @apply w-full h-full rounded-md bg-transparent placeholder-secondary-grey-300;
  }
  @media (min-width: 640px) {
    .custom-datepicker-wrapper .react-datepicker-wrapper input {
      width: 100% !important;
      margin-top: 5px !important;
      background-color: #5095fe !important;
      /* @apply sm:w-[100%] sm:mt-5 ; */
    }
  }
  .custom-datepicker-wrapper .react-datepicker-popper {
    @apply z-20;
  }
  .custom-pagination-wrapper .custom-nav.disabled a {
    background: transparent;
    cursor: not-allowed;
  }
  .custom-pagination-wrapper .custom-nav.disabled {
    cursor: not-allowed;
    background: white;
    @apply text-title;
  }
  .nav-active {
    position: relative;
  }
  .nav-active::after {
    position: absolute;
  }
  .visible .ql-toolbar.ql-snow {
    border: none !important;
    padding: 0;
  }
  .visible .ql-container.ql-snow {
    border: none !important;
  }
  .visible .ql-editor {
    padding: 12px 0;
  }
  .visible .ql-snow .ql-picker-label {
    padding-left: 0;
  }
  .availability-popup .close-btn {
    right: -40px;
  }
  .ql-container.ql-snow {
    border: 0;
  }
  .loader {
    border: 2px solid #fff;
    border-radius: 50%;
    border-top: 2px solid #5095fe;
    width: 12px;
    height: 12px;
    /* Safari */
    animation: spin 2s linear infinite;
  }
  /* Safari */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .common-editor .ql-toolbar.ql-snow {
    border: none;
  }
  .common-editor .ql-container.ql-snow {
    border: none;
  }
  .common-editor .ql-snow .ql-picker-label {
    padding-left: 5px;
  }
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgb(243 244 246);
}
